import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SoulModule } from '@esteco/soul';
import { HttpClientModule } from '@angular/common/http';
import { AvatarComponent } from './avatar/avatar.component';
import { KeycloakService } from './keycloak.service';

@NgModule({
  declarations: [
    AppComponent, AvatarComponent
  ],
  imports: [
    BrowserModule,
    SoulModule,
    HttpClientModule
  ],
  providers: [
    KeycloakService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}
