import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { KeycloakService, KeycloakUser } from '../keycloak.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnDestroy {
  private user: User = new User(null);
  private subscription: Subscription;


  constructor(private keycloakService: KeycloakService, private changeDetectionRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.subscription = this.keycloakService.loadUserInfo().subscribe((user) => {
      this.user = new User(user)
      this.changeDetectionRef.detectChanges();
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public logout(): void {
    this.keycloakService.logout();
  }

  public editProfile(): void {
    this.keycloakService.editAccount();
  }
}


export class User {
  public email: string;
  public lastName: string;
  public firstName: string;
  public username: string;
  public id: string;

  public constructor(keyCloakUser: KeycloakUser) {
    this.firstName = keyCloakUser?.given_name;
    this.lastName = keyCloakUser?.family_name;
    this.email = keyCloakUser?.email;
    this.id = keyCloakUser?.sub;
    this.username = keyCloakUser?.preferred_username;
  }

  public getFullName(): string {
    if (this.firstName != null && this.lastName != null) {
      return this.firstName + ' ' + this.lastName;
    } else {
      return '';
    }
  }

  public getInitials(): string {
    if (this.firstName != null && this.lastName != null) {
      return this.firstName.charAt(0) + this.lastName.charAt(0);
    } else {
      return '';
    }
  }
}


