import { Injectable } from '@angular/core';
import * as Keycloak from 'keycloak-js';
import { KeycloakInitOptions } from 'keycloak-js';
import { Observable, ReplaySubject } from 'rxjs';


@Injectable()
export class KeycloakService {

  public static auth: any = {};

  public static init(): Observable<any> {
    KeycloakService.auth.loggedIn = false;

    const keycloakAuth: any = Keycloak();
    const subject: ReplaySubject<any> = new ReplaySubject<any>(1);

    const initOptions: KeycloakInitOptions = {
      onLoad: 'login-required',
      checkLoginIframe: false
    };

    keycloakAuth.init(initOptions)
      .success(() => {
        KeycloakService.auth.loggedIn = keycloakAuth.authenticated;
        KeycloakService.auth.authz = keycloakAuth;
        KeycloakService.auth.authz.onAuthRefreshError = () => KeycloakService.auth.authz.login();

        subject.next(keycloakAuth.authenticated);
      })
      .error(() => {
        subject.error('Failed to connect to keycloak');
      });

    return subject;
  }

  public logout(): void {
    const redirectUri: string = this.isHashedUri(location.href) ? this.extractRootUri(location.href) : location.href;

    const options: any = {
      redirectUri: redirectUri
    };

    KeycloakService.auth.authz.logout(options)
      .success(() => {
        KeycloakService.auth.loggedIn = false;
        KeycloakService.auth.authz = null;
      });
  }

  public login(): Observable<any> {
    const subject: ReplaySubject<any> = new ReplaySubject<any>(1);

    KeycloakService.auth.authz.login()
      .success(() => {
        subject.next(KeycloakService.auth.authz.authenticated);
      })
      .error(() => {
        subject.error('Failed to login');
      });

    return subject;
  }

  public editAccount(): Observable<any> {
    const subject: ReplaySubject<any> = new ReplaySubject<any>(1);
    KeycloakService.auth.authz.accountManagement()
      .error(() => {
        subject.error('Account management failed');
      });
    return subject;
  }

  public loadUserInfo(): Observable<KeycloakUser> {
    const subject: ReplaySubject<KeycloakUser> = new ReplaySubject<KeycloakUser>(1);
    if (KeycloakService.auth.loggedIn) {
      KeycloakService.auth.authz.loadUserInfo()
        .success((userInfo) => {
          subject.next(userInfo);
        })
        .error(() => {
          subject.error('Failed to load user info');
        });
    } else {
      subject.error('User not logged In');
    }
    return subject;
  }

  public getToken(): Observable<string> {
    const subject: ReplaySubject<string> = new ReplaySubject<string>(1);
    if (KeycloakService.auth.authz.token) {
      KeycloakService.auth.authz.updateToken(15)
        .success(() => {
          subject.next(<string>KeycloakService.auth.authz.token);
        })
        .error(() => {
          const errorMessage = 'Failed to refresh token';
          subject.error(errorMessage);
          KeycloakService.auth.authz.clearToken();
          this.login();
        });
    } else {
      subject.next('');
    }
    return subject;
  }

  private extractRootUri(uri: string): string {
    return uri.substring(0, uri.indexOf('#'));
  }

  private isHashedUri(uri: string): boolean {
    return uri.indexOf('#') !== -1;
  }
}


export interface KeycloakUser {
  given_name: string;
  family_name: string;
  sub: string;
  email: string;
  preferred_username: string;
}
